@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}
html {
  scroll-behavior: smooth;
  scroll-padding: 7rem;
}
body {
  line-height: 1.2;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  color: #5c5c77;
}

.futura_bold {
  font-family: 'futura';
}
.logo {
  mix-blend-mode: multiply;
}
.primary-bg {
  background-color: #1e1e4b;
}
.secondary-text {
  color: #1e1e4b;
}
.secondary-bg {
  background-color: #fda507;
}
.primary-text {
  color: #fda507;
}
.background {
  background-color: #f8f8f8;
}
.footer li a {
  font-size: 16px;
  color: #5c5c77;
}
.footer li a:hover {
  color: #ffbc3b;
}

.primary-heading {
  color: #1e1e4b;
  font-family: 'futura';
  font-weight: 700;
  line-height: 1.2;
}
p,
.paragraph {
  font-weight: 500;
  color: #57577b;
  font-size: 16px;
  line-height: 2;
  font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1e1e4b;
  font-family: futura;
  font-weight: 700;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 60px;
}

@media (max-width: 575px) {
  h1,
  .h1 {
    font-size: 45px;
  }
}

h2,
.h2 {
  font-size: 40px;
}

@media (max-width: 575px) {
  h2,
  .h2 {
    font-size: 30px;
  }
}

h3,
.h3 {
  font-size: 25px;
}

@media (max-width: 575px) {
  h3,
  .h3 {
    font-size: 20px;
  }
}

h4,
.h4 {
  font-size: 20px;
}

@media (max-width: 575px) {
  h4,
  .h4 {
    font-size: 18px;
  }
}

h5,
.h5 {
  font-size: 18px;
}

@media (max-width: 575px) {
  h5,
  .h5 {
    font-size: 16px;
  }
}

h6,
.h6 {
  font-size: 16px;
}

@media (max-width: 575px) {
  h6,
  .h6 {
    font-size: 14px;
  }
}
.header {
  font-weight: 600;
  cursor: pointer;
}
.header ion-icon {
  font-size: 14px;
}
.flexPack {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navigation {
  /* background-image: linear-gradient(to right, transparent 35%, #1e1e4b 35%); */
  background-color: white;
  /* border: 1px solid #1e1e4b; */
  transition: 0.2s ease;
  box-shadow: 0px 2px 4px #e8e8ee3c;
}

.pages::after,
.apply-dropdown-link::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.navLinks li {
  position: relative;
}

@media (min-width: 1024px) {
  .navLinks > li::after {
    position: absolute;
    top: 44px;
    content: '';
    width: 0%;
    height: 6px;
    background: #1e1e4b;
    display: block;
    transition: width 0.3s, border-bottom 0.3s;
  }
  .navLinks li:hover::after {
    width: 100%;
  }
  .navLinks .active::after {
    width: 100%;
    border-bottom: 5px solid #1e1e4b;
  }
}

.apply-dropdown:hover .apply-dropdown-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
}

.apply-dropdown-menu {
  z-index: 100;
  position: absolute;
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.12);
  border-bottom: 5px solid #ffbc3b;
  padding: 0.5rem 2rem;
  border-radius: 0;
  visibility: hidden;
  transition: 0.3s ease;
  opacity: 0;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  background: #fff;
}
@media (min-width: 1024px) {
  .navLinks .dropdown-menu {
    position: absolute;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.12);
    border-bottom: 5px solid #ffbc3b;
    padding: 2rem;
    width: 12rem;
    border-radius: 0;
    display: block;
    visibility: hidden;
    transition: 0.3s ease;
    opacity: 0;
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
    background: #fff;
  }
  .navLinks .dropdown:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(32px);
    transform: translateY(32px);
  }
  .navLinks .dropdown-item {
    color: #1e1e4b;
    transition: 0.2s ease;
    font-family: 'Poppins', sans-serif;
  }
}
.navLinks .dropdown-menu {
  margin-inline-end: 0.2rem;
  background-color: #f8f8f8;
}
@media (max-width: 1024px) {
  .navLinks {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.263);
    padding-top: 1rem;
    height: 100vh;
    width: 15rem;
    top: 0;
    position: fixed;
    background-color: white;
    color: #1e1e4b;
    transition: all 0.3s ease;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
  .navLinks li {
    padding-inline-start: 2rem;
    padding-block: 0.6rem;
  }
}

.event-card {
  cursor: pointer;
  max-width: 100%;
  min-height: 28rem;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.223);
}
.event-card:hover {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.264);
}
.event-card img {
  border-radius: 10px 10px 0 0;
}
.events-page-card img {
  border-radius: 10px 10px 0 0;
}
@media (max-width: 1024px) {
  .event-card {
    min-height: 24rem;
  }
}
.events-page-card {
  cursor: pointer;
  max-width: 100%;
  min-height: 27rem;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.223);
}
.course-card {
  cursor: pointer;
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.223);
}
.course-card:hover {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.264);
}
.course-card img {
  border-radius: 10px 10px 0 0;
}
.teachers-page-card {
  cursor: pointer;
  max-width: 100%;
  /* max-height: rem; */
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.223);
}
.teachers-page-card:hover {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.264);
}
.teachers-page-card img {
  border-radius: 10px 10px 0 0;
}
@media (max-width: 1024px) {
  .course-card {
    min-height: 24rem;
  }
}
.hover:hover {
  background-color: #ffbc3b;
  color: white;
  transition: 0.5s all ease;
}
.button:hover {
  color: white;
  background-color: transparent;
  transition: 0.5s all ease;
}
.left-section {
  border-radius: 10px 0 0 10px;
}
.right-section {
  border-radius: 0 10px 10px 0;
}
.right-section input {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #5c5c77;
}
.right-section input:focus,
.contact-form input:focus,
textarea:focus {
  outline-width: 2px;
  outline-color: #5c5c77;
}
.align-userInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.section {
  flex: 1 1 50%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 5rem;
}

.section:nth-child(odd) {
  flex-direction: row-reverse;
}
@media (max-width: 768px) {
  .section {
    flex: 1 0 100%;
    flex-direction: column-reverse;
    margin-bottom: 3rem;
  }
  .section:nth-child(odd) {
    flex-direction: column-reverse;
  }
}

.admission-apply .image::after {
  content: '';
  display: inline-block;
  width: 12rem;
  height: 0.2rem;
  border-top: 3px dashed white;
  align-self: center;
}
@media (max-width: 1024px) {
  .admission-apply .image::after {
    width: 9rem;
  }
}
@media (max-width: 768px) {
  .apply-section {
    display: flex;
    gap: 1rem;
  }
  .admission-apply .image {
    flex-direction: column;
  }
  .admission-apply .image::after {
    width: 6rem;
    rotate: 90deg;
  }
}

.admission-apply h5 {
  padding-top: 1rem;
}

.related-links {
  position: sticky;
  top: 7rem;
}
.parents h4,
.teachers h4 {
  margin-top: 2rem;
  margin-bottom: 0.75rem;
}

/* carousel in home page for testimonial */
.embla {
  max-width: 70rem;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-spacing-sm: 1.6rem;
  --slide-size-sm: 50%;
  --slide-spacing-lg: 2rem;
  --slide-size-lg: calc(100% / 3);
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
@media (min-width: 750px) {
  .embla__container {
    margin-left: calc(var(--slide-spacing-sm) * -1);
  }
}
@media (min-width: 1200px) {
  .embla__container {
    margin-left: calc(var(--slide-spacing-lg) * -1);
  }
}
.embla__slide {
  min-width: 0;
  flex: 0 0 var(--slide-size);
  padding-left: var(--slide-spacing);
}
@media (min-width: 750px) {
  .embla__slide {
    flex: 0 0 var(--slide-size-sm);
    padding-left: var(--slide-spacing-sm);
  }
}
@media (min-width: 1200px) {
  .embla__slide {
    flex: 0 0 var(--slide-size-lg);
    padding-left: var(--slide-spacing-lg);
  }
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
}
.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
}
.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}
